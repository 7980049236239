import React from 'react'
import pop from "../assets/pop.png"
function Pop() {
  return (
    <div className="Pop flex flex-col items-center justify-center h-screen">
        <div className="container flex flex-col items-center justify-center  gap-2">
            <div className="pop flex items-center justify-center flex-col px-4">
                <img src={pop} alt="" />
                <img src={pop} alt="" />
                <img src={pop} alt="" />
                <img src={pop} alt="" />

            </div>
            <h1 className='font-crt text-4xl text-[#0850F8]'>MORE POP-POP GAME :)</h1>
            <h1 className='font-crt text-4xl text-[#0850F8]'>ENJOY PEEPS</h1>
            <h1 className='font-crt text-4xl text-[#0850F8]'>BUY BSED !</h1>

        </div>
    </div>
  )
}

export default Pop