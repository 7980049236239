import React from "react";
import pinpoint from "../assets/pinpoint.png";
import tinypin from "../assets/tinypinpoint.png";
function Roadmap() {
  return (
    <div className="Roadmap flex flex-col items-center justify-center min-h-screen" id="roadmap">
      <div className="container flex flex-col items-center justify-center gap-10 my-10">
        <h1 className="text-6xl text-[#0850F8] font-crt text-center">
          ROADMAP
        </h1>
        <div className="row md:flex-row flex-col flex items-center justify-center gap-20">
          <div className="card flex flex-col items-start justify-center gap-6">
            <div className="row flex flex-row items-center justify-center gap-4 font-mono text-xl font-bold text-[#0850F8]">
              <img src={pinpoint} alt="" />
              <h1>Q2 - 2024</h1>
            </div>
            <div className="col flex flex-col items-start justify-center gap-4">
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Public Sale
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Listing
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                CEX & DEX
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Launchpad Release
              </div>
            </div>
          </div>
          <div className="card flex flex-col items-start justify-center gap-6">
            <div className="row flex flex-row items-center justify-center gap-4 font-mono text-xl font-bold text-[#0850F8]">
              <img src={pinpoint} alt="" />
              <h1>Q3 - 2024</h1>
            </div>
            <div className="col flex flex-col items-start justify-center gap-4">
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Token Rewards

              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Mobile app testing
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Mobile app beta
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Huge Partnerships
              </div>
            </div>
          </div>
          <div className="card flex flex-col items-start justify-center gap-6">
            <div className="row flex flex-row items-center justify-center gap-4 font-mono text-xl font-bold text-[#0850F8]">
              <img src={pinpoint} alt="" />
              <h1>Q4 - 2024</h1>
            </div>
            <div className="col flex flex-col items-start justify-center gap-4">
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                NFT Marketplace
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Beta games release
              </div>
              <div className="row flex flex-row items-center justify-center gap-4 font-mono text-base  text-[#0850F8]">
                <img src={tinypin} alt="" />
                Whale Airdrops
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
