import Home from './pages/Home';
import "./App.css"
import About from './pages/About';
import Features from './pages/Features';
import Roadmap from './pages/Roadmap';
import Pop from './pages/Pop';
import logoBlue from "./assets/bluelogo.png";
import bluedoge from "./assets/bluedoge.png";
import mail from "./assets/mail.png"
import x from "./assets/x.png"
import tg from "./assets/tg.png"
function App() {
  return (
    <div className="App">
      <Home />
      <About />
      <Features />
      <Roadmap />
      {/* <Pop /> */}
      <Footer />
    </div>
  );
}



function Footer() {
  return (
    <footer className='bg-black min-h-96 flex items-center justify-center'>
      <div className="container flex flex-col items-center justify-center p-4">
        <div className="flex md:flex-row flex-col md:items-center gap-4 justify-between mb-14 w-full">
          <div className="left flex flex-col items-start justify-start md:w-1/4 gap-6">
            <div className="logo flex flex-row items-center justify-center gap-4 h-10">
              <img src={logoBlue} alt="" className="h-full" />
              <img src={bluedoge} alt="" className="h-1/2" />
            </div>
            <h1 className='text-sm font-mono text-white font-light '><span className='font-bold'>$BSED</span> stands at the forefront of innovation, poised to revolutionize the landscape of decentralized finance.</h1>
          </div>
          <div className="right flex flex-col items-start justify-center">
            <h1 className='text-xl text-white font-mono'>Contact Us</h1>
            <a href='mailto:support@webasedoge.com' className='text-xl text-white font-mono'>support@webasedoge.com</a>

          </div>
        </div>
        <div className="flex md:flex-row flex-col md:items-end justify-between w-full">
          <div className="menu flex md:flex-row items-center justify-center md:gap-16 gap-4">
            <a
              href="#base"
              className="text-lg font-ibm text-white hover:text-blue-700"
            >
              BASE
            </a>
            <a
              href="#about"
              className="text-lg font-ibm text-white hover:text-blue-700"
            >
              ABOUT
            </a>

            <a
              href="#features"
              className="text-lg font-ibm text-white hover:text-blue-700"
            >
              FEATURES
            </a>
            <a
              href="#roadmap"
              className="text-lg font-ibm text-white hover:text-blue-700"
            >
              ROADMAP
            </a>
          </div>
          <div className="menu flex flex-row items-center justify-center gap-4 my-2">
            <a href="mailto:support@webasedoge.com">
              <img src={mail} alt="" />
            </a>
            <a href="https://x.com/webasedoge">
              <img src={x} alt="" />
            </a>
            <a href="https://t.me/basedogechat">
              <img src={tg} alt="" />
            </a>
          </div>
        </div>
        <hr className='w-full h-0.5 my-4 bg-[#0850F8] border-0' />
        <div className="flex w-full items-start justify-start">
          <h1 className='text-base font-mono text-white'>© 2024  weBASEDOGE  •  All Rights Reserved</h1>
        </div>
      </div>
    </footer>
  )
}

export default App;
