import React from "react";
import logoBlue from "../assets/bluelogo.png";
import bluedoge from "../assets/bluedoge.png";
function Home() {
  return (
    <div className="Home min-h-screen flex items-center justify-center " id="base">
      <Navbar />
      <div className="container flex items-center justify-center flex-col md:w-1/2 gap-10 ">
        <h1 className="text-[#0850F8] font-crt text-6xl text-center">
          TRANSFORMING THE <br />
          FUTURE OF <span>LAUNCHPADS</span>
        </h1>
        <p className="text-[#0850F8] font-ibm font-light text-xl text-center">
          <span>Base-doge</span> stands at the forefront of innovation, poised
          to revolutionize the landscape of decentralized finance.
        </p>
        <a
          href=""
          className="w-32 border-2 border-[#0850F8] text-[#0850F8] text-center flex items-center justify-center rounded-xl text-lg"
        >
          Learn More
        </a>
      </div>
    </div>
  );
}

function Navbar() {
  return (
    <div className="Navbar bg-black w-full md:h-[70px] h-[50px] flex flex-row items-center justify-center fixed top-0 shadow-2xl shadow-[#00123B]">
      <div className="container flex flex-row items-center justify-between h-full py-4 px-4 ">
        <div className="logo flex flex-row items-center justify-center gap-4 h-full">
          <img src={logoBlue} alt="" className="h-full" />
          <img src={bluedoge} alt="" className="h-1/2" />
        </div>
        <div className="menu md:flex flex-row items-center justify-center gap-16 hidden ">
          <a
            href="#base"
            className="text-lg font-ibm text-white hover:text-blue-700"
          >
            BASE
          </a>
          <a
            href="#about"
            className="text-lg font-ibm text-white hover:text-blue-700"
          >
            ABOUT
          </a>

          <a
            href="#features"
            className="text-lg font-ibm text-white hover:text-blue-700"
          >
            FEATURES
          </a>
          <a
            href="#roadmap"
            className="text-lg font-ibm text-white hover:text-blue-700"
          >
            ROADMAP
          </a>
          <a
            href=""
            className="text-lg px-1  bg-gradient-to-l from-[#083271] to-[#0051FF] font-ibm text-white border-white border-[1px] rounded-md"
          >
            Marketplace
          </a>
        </div>
        <a
          href=""
          className="text-lg px-1 md:hidden  bg-gradient-to-l from-[#083271] to-[#0051FF] font-ibm text-white border-white border-[1px] rounded-md"
        >
          Marketplace
        </a>
      </div>
    </div>
  );
}

export default Home;
