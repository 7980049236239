import React from "react";

function Features() {
  return (
    <div className="Features flex flex-col items-center justify-center min-h-screen" id="features">
      <div className="container flex flex-col items-center justify-center gap-10 my-20 md:my-0">
        <h1 className="text-6xl text-[#0850F8] font-crt text-center">
          FEATURES
        </h1>
        <div className="row flex md:flex-row flex-col items-center justify-center gap-10 ">
          <div className="card w-80 h-80 border-[#0850F8] border-2 rounded-2xl flex flex-col items-center gap-12 p-6">
            <h1 className="font-mono text-lg font-bold text-[#0850F8]">
              Economic sustainability
            </h1>
            <hr className="w-4/5 h-[2px] bg-[#0850F8]" />
            <h1 className="font-ibm text-md  text-[#0850F8] text-center">
              We pledge to burn 50% of revenue from our launchpad and NFT
              marketplace, and strategically buy back our token "Base Doge" to
              maintain price stability and investor confidence.
            </h1>
          </div>
          <div className="card w-80 h-80 border-[#0850F8] border-2 rounded-2xl flex flex-col items-center gap-12 py-6">
            <h1 className="font-mono text-lg font-bold text-[#0850F8] text-center">
              Proprietary NFT marketplace
            </h1>
            <hr className="w-4/5 h-[2px] bg-[#0850F8] text-center" />
            <h1 className="font-ibm text-md  text-[#0850F8] text-center">
              Our NFT marketplace amplifies project visibility and cultivates an
              interconnected ecosystem where creativity thrives.
            </h1>
          </div>
          <div className="card w-80 h-80 border-[#0850F8] border-2 rounded-2xl flex flex-col items-center gap-12 py-6">
            <h1 className="font-mono text-lg font-bold text-[#0850F8] text-center">
              Dynamic project selection
            </h1>
            <hr className="w-4/5 h-[2px] bg-[#0850F8]" />
            <h1 className="font-ibm text-md  text-[#0850F8] text-center">
              We curate projects with unparalleled potential, fostering an
              ecosystem of innovation and dynamism.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
