import React from "react";
import pop from "../assets/pop.png";
function About() {
  return (
    <div
      className="About min-h-screen flex items-center justify-center  flex-col "
      id="about"
    >
      <div className="container items-center flex flex-col gap-10 justify-center ">
        <h1 className="text-6xl text-[#0850F8] font-crt text-center">
          ABOUT US
        </h1>
        <div className="card flex flex-col items-center gap-10 md:p-20 p-4 m-2 md:w-4/6 border-[#0850F8] border-2 rounded-2xl font-mono  text-lg text-[#0850F8]">
          <p>
            In the ever-evolving landscape of decentralized finance, the arrival
            of Basedoge marks a watershed moment. As the premier dynamic hyped
            launchpad operating on the base chain, Basedoge is poised to
            redefine the very fabric of project incubation and investment within
            the crypto sphere.
          </p>
          <p>
            Unlike traditional launchpads that adhere to static frameworks,
            Basedoge embraces fluidity and adaptability, paving the way for the
            emergence of groundbreaking ventures that resonate with the
            zeitgeist of our times.
          </p>
        </div>
        {/* <img src={pop} alt="" className="w-fit px-4" /> */}

        {/* <h1 className="text-4xl text-[#0850F8] font-crt text-center m-2">
          POP-POP GAME :) <br /> BUY BSED!
        </h1> */}
      </div>
    </div>
  );
}

export default About;
